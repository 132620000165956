import { makeStyles } from '@mui/styles';
import { default as React } from 'react';
import type { Weather } from '../../utils/getWeather';

const useStyles = makeStyles({
  weatherBGCloudy: {
    background: 'linear-gradient(to top right, #b6c6c9, #8fa3ad)',
  },
  weatherBGSunny: {
    background: 'linear-gradient(to bottom left, #ffffd0, #007cf0)',
  },
  weatherBGRainy: {
    background: 'linear-gradient(to top, #647d8e, #a8c0c0)',
  },
  weatherBGSnowy: {
    background: 'linear-gradient(to bottom, #ffffff, #acc2d9)',
  },
  weatherBGWindy: {
    background: 'linear-gradient(to right, #c4e0e5, #4ca1af)',
  },
  weatherWidget: {
    width: '100%',
    height: '100%',
    padding: '20px',
    color: 'white',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  weatherWidgetData: {
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& h2': {
      fontSize: '8em',
      fontWeight: 500,
    },
  },
  weatherEmptyState: {
    width: '100%',
    height: '100%',
    padding: '20px',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'var(--emptyStateBackground, linear-gradient(to top right, #b6c6c9, #8fa3ad))',
  },
});

const WeatherWidget = ({
  location = '---',
  temperature = -1,
  conditions = 'Sunny',
  isEmpty = false,
}: Weather & { isEmpty?: boolean }): React.ReactElement => {
  const classes = useStyles();

  const conditionClassMap = {
    Cloudy: classes.weatherBGCloudy,
    Sunny: classes.weatherBGSunny,
    Rainy: classes.weatherBGRainy,
    Snowy: classes.weatherBGSnowy,
    Windy: classes.weatherBGWindy,
  };

  if (isEmpty) {
    return (
      <div className={`${classes.weatherWidget} ${classes.weatherEmptyState}`}>
        <div className={classes.weatherWidgetData}>
          <p>Enter a city to see local weather</p>
          <p>try: what's the weather like in Berkeley?</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${classes.weatherWidget} ${
        conditionClassMap[conditions as keyof typeof conditionClassMap] || classes.weatherBGSunny
      }`}
    >
      <div className={classes.weatherWidgetData}>
        <p>{location}</p>
        <h2>{temperature !== -1 ? `${temperature}°F` : temperature}</h2>
        <p>{conditions}</p>
      </div>
    </div>
  );
};

export default WeatherWidget;
