import { makeStyles } from '@mui/styles';
import moment from 'moment';
import type { RequiredActionFunctionToolCall } from 'openai/resources/beta/threads/runs/runs.mjs';
import React, { useCallback, useState } from 'react';
import Chat from '../components/LLM/Chat';
import WeatherForecastWidget from '../components/LLM/WeatherForecastWidget';
import WeatherWidget from '../components/LLM/WeatherWidget';
import { useFarmStore, useObservationSiteStore } from '../dataHandlers/RootStore';
import type { FirebaseTokenProvider } from '../interfaces/IUser';
import {
  getWeather,
  getWeatherForecastLatLon,
  locationFromLatLon,
  type Weather,
  type WeatherForecast,
} from '../utils/getWeather';
import useAuthToken from '../utils/useAuthToken';

const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100vh',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    height: 'calc(100% - 40px)',
    gap: 20,
    margin: 20,
    justifyContent: 'space-between',
    '& > *': {
      borderRadius: 16,
      overflow: 'hidden',
      width: '100%',
      height: '50%',
    },
  },
  chatContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  chat: {
    maxWidth: 600,
    width: '100%',
    height: '100%',
  },
}));

const fetchSoilMoistureData = async (observationSiteId: string, tokenProvider: FirebaseTokenProvider) => {
  // const endTime = Date.now();
  // const startTime = endTime - 7 * 24 * 60 * 60 * 1000; // 7 days ago
  // const url = `${BASE_URL}/observation-sites/${observationSiteId}/observations/${startTime}-${endTime}`;
  // const response = await fetch(url, {
  //   headers: {
  //     Authorization: `Bearer ${await tokenProvider()}`,
  //   },
  // });
  // if (!response.ok) {
  //   throw new Error(`Failed to fetch soil moisture data: ${response.statusText}`);
  // }
  // const data = await response.json();
  // return JSON.stringify(data);
};

const ChatPage = () => {
  const classes = useStyles();
  const [weatherData, setWeatherData] = useState<Weather>();
  const [weatherForecast, setWeatherForecast] = useState<WeatherForecast[]>([]);
  const farmStore = useFarmStore();
  const selectedFarm = farmStore.selectedFarm;
  const observationSite = selectedFarm?.observationSiteOrder?.[0];
  const tokenProvider = useAuthToken();
  const observationSiteStore = useObservationSiteStore();

  const getData = useCallback(async () => {
    const d = farmStore.areas.filter((area) => area.kind == 'site').map((area) => area.siteDetails);
    const details = d[0];
    if (details.transformed.status == 'ready') {
      const data = details.transformed.value;
      return data;
    }
    return undefined;
  }, [farmStore.areas]);

  console.log({ weatherForecast });
  console.log({ weatherData });
  const functionCallHandler = async (call: RequiredActionFunctionToolCall) => {
    console.log(call?.function?.name);
    if (call?.function?.name === 'get_current_weather') {
      const args = JSON.parse(call.function.arguments as string);
      const data = await getWeather(args.location as string);
      setWeatherData(data);
      return JSON.stringify(data);
    } else if (call?.function?.name === 'get_weather_forecast') {
      const args = JSON.parse(call.function.arguments as string);
      const siteDetails = farmStore.areas.filter((area) => area.kind == 'site').map((area) => area.siteDetails);
      const data = await getWeatherForecastLatLon(
        siteDetails[0].site.coordinates.lat,
        siteDetails[0].site.coordinates.lng
      );
      setWeatherForecast(data);
      return JSON.stringify(data);
    } else if (call?.function?.name === 'get_soil_moisture_data') {
      farmStore.setSelectedDates(moment().subtract(7, 'days').startOf('day'), moment().endOf('day'));
      // delay 1 second
      await new Promise((resolve) => {
        // check area status every 0.5s until all areas are ready
        const interval = setInterval(() => {
          if (
            farmStore.areas
              .filter((area) => area.kind == 'site')
              .every((area) => area.siteDetails.transformed.status == 'ready')
          ) {
            clearInterval(interval);
            resolve(true);
          }

          if (
            farmStore.areas
              .filter((area) => area.kind == 'site')
              .some((area) => area.siteDetails.transformed.status == 'error')
          ) {
            clearInterval(interval);
            resolve(true);
          }
        }, 500);
      });
      const args = JSON.parse(call.function.arguments as string);
      const data = await getData();
      if (!data?.data) return JSON.stringify({});

      // Group data by day
      const dailyStats = data.data.reduce(
        (
          acc: Record<
            string,
            {
              pawTop: { min: number; max: number; sum: number; count: number };
              pawBot: { min: number; max: number; sum: number; count: number };
            }
          >,
          item
        ) => {
          const day = moment(item.timestamp).format('YYYY-MM-DD');
          if (!item.pawBot || !item.pawTop) return acc;

          if (!acc[day]) {
            acc[day] = {
              pawTop: { min: item.pawTop, max: item.pawTop, sum: item.pawTop, count: 1 },
              pawBot: { min: item.pawBot, max: item.pawBot, sum: item.pawBot, count: 1 },
            };
          } else {
            acc[day] = {
              pawTop: {
                min: Math.min(acc[day].pawTop.min, item.pawTop),
                max: Math.max(acc[day].pawTop.max, item.pawTop),
                sum: acc[day].pawTop.sum + item.pawTop,
                count: acc[day].pawTop.count + 1,
              },
              pawBot: {
                min: Math.min(acc[day].pawBot.min, item.pawBot),
                max: Math.max(acc[day].pawBot.max, item.pawBot),
                sum: acc[day].pawBot.sum + item.pawBot,
                count: acc[day].pawBot.count + 1,
              },
            };
          }
          return acc;
        },
        {}
      );

      // Calculate averages and format result
      const dailyData = Object.entries(dailyStats).map(([date, stats]) => ({
        date,
        description: {
          pawTop: `Plant available water at depth 20cm`,
          pawBot: `Plant available water at depth 40cm`,
        },
        pawTop: {
          min: stats.pawTop.min,
          max: stats.pawTop.max,
          avg: stats.pawTop.sum / stats.pawTop.count,
        },
        pawBot: {
          min: stats.pawBot.min,
          max: stats.pawBot.max,
          avg: stats.pawBot.sum / stats.pawBot.count,
        },
      }));
      console.log({ dailyData });
      return JSON.stringify(dailyData);
    } else if (call?.function?.name === 'get_farm_location') {
      const siteDetails = farmStore.areas.filter((area) => area.kind == 'site').map((area) => area.siteDetails);
      const data = await locationFromLatLon(
        siteDetails[0].site.coordinates.lat,
        siteDetails[0].site.coordinates.lng
      );
      return JSON.stringify(data);
    } else if (call?.function?.name === 'get_crop_type') {
      const args = JSON.parse(call.function.arguments as string);
      const siteDetails = farmStore.areas.filter((area) => area.kind == 'site').map((area) => area.siteDetails);
      const data = siteDetails[0].site.cropType;
      return JSON.stringify(data ?? 'Crop type unknown, ask the farmer');
    } else if (call?.function?.name === 'get_farm_name') {
      console.log(farmStore.availableFarms);
      const farm = farmStore.availableFarms.find((farm) => farm.name.includes('Kommune'));
      return JSON.stringify(farm?.name ?? 'Farm name unknown, ask the farmer');
    }
    return Promise.resolve('');
  };

  return (
    <main className={classes.main}>
      <div className={classes.container}>
        <div className={classes.column}>
          {weatherData && <WeatherWidget {...weatherData} />}
          {weatherForecast?.length > 0 && <WeatherForecastWidget forecast={weatherForecast} />}
          {/* <FileViewer /> */}
        </div>
        <div className={classes.chatContainer}>
          <div className={classes.chat}>
            <Chat functionCallHandler={functionCallHandler} />
          </div>
        </div>
      </div>
    </main>
  );
};

export default ChatPage;
