import { makeStyles } from '@mui/styles';
import { default as React } from 'react';
import type { WeatherForecast } from '../../utils/getWeather';

const useStyles = makeStyles({
  weatherBGCloudy: {
    background: 'linear-gradient(to top right, #b6c6c9, #8fa3ad)',
  },
  weatherBGSunny: {
    background: 'linear-gradient(to bottom left, #ffffd0, #007cf0)',
  },
  weatherBGRainy: {
    background: 'linear-gradient(to top, #647d8e, #a8c0c0)',
  },
  weatherBGWindy: {
    background: 'linear-gradient(to right, #c4e0e5, #4ca1af)',
  },
  forecastContainer: {
    padding: '20px',
    borderRadius: '12px',
    color: 'white',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  forecastDay: {
    padding: '16px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
  },
});

const WeatherForecastWidget = ({ forecast }: { forecast: WeatherForecast[] }) => {
  const classes = useStyles();

  const getWeatherCondition = (windSpeed: number, precipitation: number) => {
    if (precipitation > 0) return 'Rainy';
    if (windSpeed > 20) return 'Windy';
    if (windSpeed > 10) return 'Cloudy';
    return 'Sunny';
  };

  const getBackgroundClass = (condition: string) => {
    const conditionClassMap = {
      Cloudy: classes.weatherBGCloudy,
      Sunny: classes.weatherBGSunny,
      Rainy: classes.weatherBGRainy,
      Windy: classes.weatherBGWindy,
    };
    return conditionClassMap[condition as keyof typeof conditionClassMap] || classes.weatherBGSunny;
  };

  return (
    <div className={classes.forecastContainer}>
      {forecast.map((day) => {
        const condition = getWeatherCondition(day.windSpeed, day.precipitation);
        return (
          <div
            key={day.timestamp.toISOString()}
            className={`${classes.forecastDay} ${getBackgroundClass(condition)}`}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <div style={{ fontSize: '1.1em', fontWeight: 500 }}>
                {day.timestamp.toLocaleDateString('en-US', { weekday: 'long' })}
              </div>
              <div style={{ fontSize: '0.9em', opacity: 0.9 }}>
                {day.timestamp.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <div style={{ fontSize: '1.2em' }}>{condition}</div>
              <div style={{ fontSize: '1.4em', fontWeight: 500 }}>{day.temperature}°C</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WeatherForecastWidget;
