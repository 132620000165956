'use client';

import { styled } from '@mui/material/styles';
import { AssistantStream } from 'openai/lib/AssistantStream';
import React, { useCallback, useEffect, useRef, useState } from 'react';
// import Markdown from 'react-markdown';
// @ts-expect-error - no types for this yet
import type { AssistantStreamEvent } from 'openai/resources/beta/assistants/assistants';
import type { RequiredActionFunctionToolCall } from 'openai/resources/beta/threads/runs/runs';
import type { ToolCall, ToolCallDelta } from 'openai/resources/beta/threads/runs/steps.mjs';
import Markdown from 'react-markdown';
import { BASE_URL } from '../../utils/consts';
import useAuthToken from '../../utils/useAuthToken';

const ChatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column-reverse',
  height: '100%',
  width: '100%',
});

const InputForm = styled('form')({
  display: 'flex',
  width: '100%',
  padding: '10px',
  paddingBottom: '40px',
  order: 1,
});

const Input = styled('input')({
  flexGrow: 1,
  padding: '16px 24px',
  marginRight: '10px',
  borderRadius: '60px',
  border: '2px solid transparent',
  fontSize: '1em',
  backgroundColor: '#efefef',
  resize: 'none',
  '&:focus': {
    outline: 'none !important',
    borderColor: '#000',
    backgroundColor: 'white',
  },
}).withComponent('textarea');

const Button = styled('button')({
  padding: '8px 24px',
  backgroundColor: '#000',
  color: 'white',
  border: 'none',
  fontSize: '1em',
  borderRadius: '60px',
  '&:disabled': {
    backgroundColor: 'lightgrey',
  },
});

const Messages = styled('div')({
  flexGrow: 1,
  overflowY: 'auto',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  order: 2,
  whiteSpace: 'pre-wrap',
});

const MessageBase = styled('div')({
  margin: '8px 0',
  padding: '8px 16px',
  alignSelf: 'flex-start',
  borderRadius: '15px',
  maxWidth: '80%',
  overflowWrap: 'break-word',
});

const UserMessageStyled = styled(MessageBase)({
  alignSelf: 'flex-end',
  color: '#fff',
  backgroundColor: '#000',
});

const AssistantMessageStyled = styled(MessageBase)({
  backgroundColor: '#efefef',
  '& img': {
    maxWidth: '100%',
    margin: '8px 0px',
    borderRadius: '8px',
  },
});

const CodeMessageStyled = styled(MessageBase)({
  padding: '10px 16px',
  backgroundColor: '#e9e9e9',
  fontFamily: 'monospace',
  counterReset: 'line',
  '& > div': {
    marginTop: '4px',
  },
  '& span': {
    color: '#b8b8b8',
    marginRight: '8px',
  },
});

type MessageProps = {
  role: 'user' | 'assistant' | 'code';
  text: string;
};

const UserMessage = ({ text }: { text: string }) => {
  return <UserMessageStyled>{text}</UserMessageStyled>;
};

const AssistantMessage = ({ text }: { text: string }) => {
  return (
    <AssistantMessageStyled>
      <Markdown>{text}</Markdown>
    </AssistantMessageStyled>
  );
};

const CodeMessage = ({ text }: { text: string }) => {
  return (
    <CodeMessageStyled>
      {text.split('\n').map((line, index) => (
        <div key={index}>
          <span>{`${index + 1}. `}</span>
          {line}
        </div>
      ))}
    </CodeMessageStyled>
  );
};

const Message = ({ role, text }: MessageProps) => {
  switch (role) {
    case 'user':
      return <UserMessage text={text} />;
    case 'assistant':
      return <AssistantMessage text={text} />;
    case 'code':
      return <CodeMessage text={text} />;
    default:
      return null;
  }
};

type ChatProps = {
  functionCallHandler?: (toolCall: RequiredActionFunctionToolCall) => Promise<string>;
};

// https://github.com/openai/openai-assistants-quickstart/
const Chat = ({
  functionCallHandler = () => Promise.resolve(''), // default to return empty string
}: ChatProps) => {
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [threadId, setThreadId] = useState('');
  const getIdToken = useAuthToken();

  console.log(`Thread ID: ${threadId}`);

  // automatically scroll to bottom of chat
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchWithAuth = useCallback(
    async (url: string, options: RequestInit) => {
      const token = await getIdToken();
      return fetch(url, {
        ...options,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          ...options.headers,
        },
      });
    },
    [getIdToken]
  );

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // create a new threadID when chat component created
  useEffect(() => {
    const createThread = async () => {
      const res = await fetchWithAuth(`${BASE_URL}/llm/threads`, {
        method: 'POST',
      });
      const data = (await res.json()) as { threadId: string };
      setThreadId(data.threadId);
      setInputDisabled(false);
    };
    createThread();
  }, [fetchWithAuth]);

  const sendMessage = async (text: string) => {
    console.log(`Sending a new message to a thread: ${threadId}`);
    const response = await fetchWithAuth(`${BASE_URL}/llm/threads/${threadId}/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        content: text,
      }),
    });
    const stream = AssistantStream.fromReadableStream(response.body as ReadableStream<Uint8Array>);
    handleReadableStream(stream);
  };

  const submitActionResult = async (
    runId: string,
    toolCallOutputs: { output: string; tool_call_id: string }[]
  ) => {
    const response = await fetchWithAuth(`${BASE_URL}/llm/threads/${threadId}/actions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        runId: runId,
        toolCallOutputs: toolCallOutputs,
      }),
    });
    const stream = AssistantStream.fromReadableStream(response.body as ReadableStream<Uint8Array>);
    handleReadableStream(stream);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!userInput.trim()) return;
    sendMessage(userInput);
    setMessages((prevMessages) => [...prevMessages, { role: 'user', text: userInput }]);
    setUserInput('');
    setInputDisabled(true);
    scrollToBottom();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!inputDisabled && userInput.trim()) {
        handleSubmit(e as unknown as React.FormEvent<HTMLFormElement>);
      }
    }
  };

  /* Stream Event Handlers */

  // textCreated - create new assistant message
  const handleTextCreated = () => {
    appendMessage('assistant', '');
  };

  // textDelta - append text to last assistant message
  const handleTextDelta = (delta: { value?: string; annotations?: any }) => {
    if (delta.value != null) {
      appendToLastMessage(delta.value);
    }
    if (delta.annotations != null) {
      console.log('Annotating last message');
      annotateLastMessage(
        delta.annotations as Array<{
          type: string;
          text: string;
          file_path?: { file_id: string };
        }>
      );
    }
  };

  const handleAnnotations = (message: {
    annotations: Array<{ type: string; text: string; file_path?: { file_id: string } }>;
  }) => {
    console.log('Annotating last message');
    annotateLastMessage(message.annotations);
  };

  // imageFileDone - show image in chat
  const handleImageFileDone = (image: { file_id: string }) => {
    appendToLastMessage(`\n![${image.file_id}](${BASE_URL}/llm/files/${image.file_id})\n`);
  };

  // toolCallCreated - log new tool call
  const toolCallCreated = (toolCall: { type: string }) => {
    if (toolCall.type != 'code_interpreter') return;
    appendMessage('code', '');
  };

  // toolCallDelta - log delta and snapshot for the tool call
  const toolCallDelta = (delta: ToolCallDelta, snapshot: ToolCall) => {
    if (delta.type != 'code_interpreter') return;
    if (!delta.code_interpreter?.input) return;
    appendToLastMessage(delta.code_interpreter.input as string);
  };

  // handleRequiresAction - handle function call
  const handleRequiresAction = async (event: AssistantStreamEvent.ThreadRunRequiresAction) => {
    const runId = event.data.id as string;
    const toolCalls = event.data.required_action.submit_tool_outputs.tool_calls;
    // loop over tool calls and call function handler
    const toolCallOutputs = await Promise.all(
      toolCalls.map(async (toolCall: { id: string }) => {
        const result = await functionCallHandler(toolCall as RequiredActionFunctionToolCall);
        console.log({ result });
        return { output: result, tool_call_id: toolCall.id };
      })
    );
    setInputDisabled(true);
    submitActionResult(runId, toolCallOutputs as { output: string; tool_call_id: string }[]);
  };

  // handleRunCompleted - re-enable the input form
  const handleRunCompleted = () => {
    setInputDisabled(false);
  };

  const handleReadableStream = (stream: AssistantStream) => {
    console.log('Handling readable stream');

    stream.on('error', (error) => {
      console.error('Stream error:', error);
      setInputDisabled(false);
    });

    // Add end handling
    stream.on('end', () => {
      console.log('Stream ended');
      setInputDisabled(false);
    });

    // messages
    stream.on('textCreated', handleTextCreated);
    stream.on('textDelta', handleTextDelta);

    // image
    stream.on('imageFileDone', handleImageFileDone);

    // code interpreter
    stream.on('toolCallCreated', toolCallCreated);
    stream.on('toolCallDelta', toolCallDelta);

    // events without helpers yet (e.g. requires_action and run.done)
    stream.on('event', (event) => {
      console.log('Event:', event);
      if (event.event === 'thread.run.created') {
        console.log('Thread run created');
        // set thinking state
        appendMessage('assistant', 'Thinking...');
      }
      if (event.event === 'thread.message.completed') {
        const messageData = event.data as unknown as {
          annotations: Array<{ type: string; text: string; file_path?: { file_id: string } }>;
        };
        if (messageData.annotations) {
          handleAnnotations(messageData);
        }
      }
      if (event.event === 'thread.run.requires_action') handleRequiresAction(event);
      if (event.event === 'thread.run.completed') handleRunCompleted();
    });
  };

  /*
    =======================
    === Utility Helpers ===
    =======================
  */

  const appendToLastMessage = (text: string) => {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      const updatedLastMessage = {
        ...lastMessage,
        text: lastMessage.text + text,
      };
      return [...prevMessages.slice(0, -1), updatedLastMessage];
    });
  };

  const appendMessage = (role: 'user' | 'assistant' | 'code', text: string) => {
    setMessages((prevMessages) => {
      // Check if the last message is a "Thinking..." message
      const lastMessage = prevMessages[prevMessages.length - 1];
      if (lastMessage?.role === 'assistant' && lastMessage?.text === 'Thinking...') {
        // Remove the thinking message and add the new message
        return [...prevMessages.slice(0, -1), { role, text }];
      }
      // Otherwise, just append the new message
      return [...prevMessages, { role, text }];
    });
  };

  const annotateLastMessage = (
    annotations: Array<{
      type: string;
      text: string;
      file_path?: { file_id: string };
      file_citation?: { file_id: string };
    }>
  ) => {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      const updatedLastMessage = {
        ...lastMessage,
      };
      annotations.forEach((annotation) => {
        if (annotation.type === 'file_path' && annotation.file_path) {
          updatedLastMessage.text = updatedLastMessage.text.replaceAll(
            annotation.text,
            ` [${annotation.file_path.file_id}](${BASE_URL}/llm/files/${annotation.file_path.file_id})`
          );
        }

        if (annotation.type === 'file_citation' && annotation.file_citation) {
          updatedLastMessage.text = updatedLastMessage.text.replaceAll(
            annotation.text,
            ` [${annotation.file_citation.file_id}](${BASE_URL}/llm/files/${annotation.file_citation.file_id})`
          );
        }
      });
      return [...prevMessages.slice(0, -1), updatedLastMessage];
    });
  };

  return (
    <ChatContainer>
      <Messages>
        {messages.map((msg, index) => (
          <Message key={index} role={msg.role} text={msg.text} />
        ))}
        <div ref={messagesEndRef} />
      </Messages>
      <InputForm onSubmit={handleSubmit}>
        <Input
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder='Enter your question'
          rows={1}
        />
        <Button type='submit' disabled={inputDisabled}>
          Send
        </Button>
      </InputForm>
    </ChatContainer>
  );
};

export default Chat;
